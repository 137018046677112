import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query StandardOperatingProcedures {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Standard Operating Procedures',
    subtitle: `Looking for consistency in your critical day-to-day operational activities? Creating standard operating procedures (often referred to by the acronym SOP's) is a good place to start. They provide the insight and clarity to everyone performing the tasks. SOP's minimize legal and safety risks for you and your organization.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: '/features/standard-operating-procedures/feature-page-header-sop.png'
  }

  const seo = {
    title: 'Workhub | Free Standard Operating Procedure Managment Tool SOPs',
    description: 'Upload Standard Operating Procedure text, videos, PDFs & documents to streamline onboarding, training, document control & improve employee engagment with our SOP Software.',
    image: '/features/standard-operating-procedures/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Build",
      para: "Use our text editor, upload a PDF, or link to a YouTube video to create Procedures. ",
    },
    {
      title: "Assign",
      para: "Choose which positions or departments in your company need to acknowledge & view Procedures.",
    },
    {
      title: "Contacts",
      para: "Set a contact so that workers can see who to connect with if they have any questions about a given Procedure.",
    },
    {
      title: "Job Hazard Analysis",
      para: "Outline hazard analysis of procedural tasks and assign to risk level, probability of hazard occurring, and severity of outcome.",
    },
    {
      title: "Set a Review Clock",
      para: "Choose a length of time the policy must be reviewed before it can be considered acknowledged.",
    },
    {
      title: "History",
      para: "Download prior versions of procedures for audit purposes and update current versions easily. ",
    },
    {
      title: "Feedback",
      para: "Collect comments from workers, respond and optionally share with workforce.",
    },
    {
      title: "Track Compliance",
      para: "View which versions have been acknowledged by workers, and grant or reset an acknowledgment if necessary. ",
    },

  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">

        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/standard-operating-procedures/procedures-sketch.png"
                    alt="Procedures Cartoon"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">
                <h3>SOP's identify the specific actions, organized into chronological steps, that if followed will:</h3>

                <ul className="checklist">
                  <li>Explain proper techniques</li>
                  <li>Explore variations, give examples and describe alternative solutions</li>
                  <li>Identify hazards including warning signs and necessary controls. Ideally they'll also link to emergency procedures and recordkeeping forms. </li>
                </ul>
              </div>


            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">

                <h3>Job Hazard Analysis</h3>

                <p >You might already have your standard operating procedures. If so, you can skip ahead to uploading and assigning them. Regardless, it's a good idea to regularly do a Job Hazard Analysis of your tasks. This means brainstorming for potential hazards. We have a list of dozens of hazard categories and sub-categories to help you get started. Then associate a Risk Level to each hazard by selecting a probability of the hazard occurring and the severity of the outcome should the hazard occur. Next choose one or more Controls (again conveniently categorized and sub-categorized) for each hazard. Lastly, choose the now-mitigated risk level based on the controlled probability and/or controlled severity.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="/features/standard-operating-procedures/procedures-admin-2.png"
                    alt="Procedures Admin Computer"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/standard-operating-procedures/procedures-tablet.png"
                    alt="Procedures Tablet View"
                  />
                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h3>Upload Procedures and Assign to Positions</h3>

                <p>You can take the JHA you prepared in the software, or prepare a document of your own and upload it to the software. The procedures can also be videos (which we highly recommend - retention is WAY higher). Administrators can also link the procedures to related forms and competencies. You can also choose how long you want workers to spend reviewing the procedure and specify a contact in the event they have questions or concerns about the procedure.</p>

                <p>Assign the procedure to the various positions in your company. At this point you do have the option of choosing an exposure Frequency based on how often each position does the task.</p>

                <p>The system takes care of a lot of the heavy lifting from this point forward. Worker compliance tabulates as users review the procedures. It also collects comments from workers which you can respond to and optionally share with all workers. The system also tracks version changes as your procedures evolve and improve.</p>

              </div>
            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-6 feature-text">

                <h3>Worker Procedure Reviews</h3>

                <p>Workers are prompted in their portal to review and comment on procedures assigned to them. Procedures are presented in sections and orders according to the structure specified earlier by administrators. They must spend the time specified, and once complete workers can comment on the procedure in case they feel important information is missing, if they want to propose a better way of performing the task, and so on. With the proper feedback loop, the safety, quality and efficiency of your operations should improve!</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/standard-operating-procedures/procedures-mobile.png"
                    alt="Procedures Mobile View"
                  />
                </ReactWOW>

              </div>


            </div>
          </div>
        </section>

        <section className="pricing_testimonial" style={{maxWidth: '1200px'}}>
        <blockquote style={{marginTop: '0px'}}>
          <div className="stars">
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
            </div>
          <p>

              "We love workhub because it’s <span className="span_gradient" style={{padding: '0px'}}>accessible</span> to our employees and sub-contractors no matter where they are. It’s a one stop shop for storing tickets and all other necessary documentation  "
 
          </p>
       
          <p className="partner_name" id="partner_name" style={{fontSize: '16px!important'}}>JAMIE - DOM SAFETY</p>

        </blockquote>
      </section>

        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>


      </main>
    </Layout>
  )
}